(function ($) {
  $(function () {


    $('#primary-menu a[href^="#"], .scroll-button a.btn').on('click', function (e) {
      e.preventDefault();

      // Get the target section ID from the href or data attribute
      var target = $(this).attr('href') || $(this).data('target');
      var $targetElement = $(target);

      if ($targetElement.length) {
        $('html, body').animate({
          scrollTop: $targetElement.offset().top - 100
        }, 1000); // Duration in milliseconds
      }
    });


    $('.nav-button').on('click', function (e) {
      e.preventDefault();
      $(this).toggleClass('active');
      $('.navigation-floating').toggleClass('opened');
      $('.overlay-black').addClass('active');

    });

    $('.nav-button.active').on('click', function () {
      $(this).removeClass('active');
      $('.navigation-floating.opened').removeClass('opened');
    });

    /*
     * Navbar fixed 
     */
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 1) {
        $('.site-header').addClass('navbar-fixed-top');
      } else {
        $('.site-header').removeClass('navbar-fixed-top');
      }
    });

    /*
     * Toggle burger menu on mobile
     */
    $('.menu-btn-burger').on('click', function () {
      $('#nav-icon').toggleClass('open-custom-menu');
      $(".mobile-menu").toggleClass('sliding-open');
    });

    /* 
     * Owl carousel
     */
    $('.owl-room-card').owlCarousel({
      margin: 40,
      items: 3,
      loop: true,
      autoplay: true,
      autoplayTimeout: 4500,
      nav: true,
      dots: false,
      animateOut: 'fadeOut',
      navText: ["<i class='icon-angle-left'></i>", "<i class='icon-angle-right'></i>"],
      // navContainer: '#customNav',
      responsive: {
        0: {
          items: 1
        },
        700: {
          items: 2,
          margin: 0,
        },
        1200: {
          items: 3,
        }
      }
    });

    /*
     * Video modal 
     */
    $("body").on("click", "#play-video-modal", function (e) {
      e.preventDefault();
      $("#video-modal").modal("show");
      $("#video-modal iframe")[0].src += "?autoplay=1";
    });

    $("#video-modal").on('hidden.bs.modal', function (e) {
      var videoUrl = $("#video-modal iframe")[0].src;
      videoUrl = videoUrl.replace("?autoplay=1", "");
      $("#video-modal iframe")[0].src = videoUrl;
    });

    /*
     * Youtube lazy loading
     */
    $('#play-video-btn').on('click', function (e) {
      e.preventDefault();
      $('#youtubeModal').modal('show');
    });

    $("#youtubeModal").on('hidden.bs.modal', function (e) {
      $("#youtubeModal iframe").attr("src", $("#youtubeModal iframe").attr("src"));
    });



    /*
     * Scroll up button
     */
    $(window).scroll(function () {
      if ($(this).scrollTop() > 150) {
        $('.scroll-to-top').addClass('show');
      } else {
        $('.scroll-to-top').removeClass('show');
        $('.scroll-to-top').addClass('animated bounceOutDown');
        $('.scroll-to-top').removeClass('animated bounceOutUp');
        $('.scroll-to-top i').css('transform', 'rotateY(0) scale(1)');
      }
    });

    $('.scroll-to-top').on("click", function () {
      $('.scroll-to-top').addClass('animated bounceOutUp');
      $('.scroll-to-top i').css('transform', 'rotateY(180deg) scale(1.2)');
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
      return false;
    });

    /*
     * Lightbox gallery
     */
    $('.gallery-images').lightGallery({
      selector: '.gallery-item',
      share: true,
      download: true,
      rotate: false,
      showThumbByDefault: true,
      loop: false,
      slideEndAnimatoin: false,
      thumbnail: true,
      allowMediaOverlap: true,
      toggleThumb: true,
      youtubePlayerParams: {
        loadYoutubeThumbnail: true,
        youtubeThumbSize: 'default',
        modestbranding: 1,
        showinfo: 0,
        rel: 0
      }
    });



    /*
     * Mmenu light 
     */
    var menu = new MmenuLight(
      document.querySelector('#my-menu'),
      'all'
    );
    if (menu) {
      var navigator = menu.navigation({
        // selectedClass: 'Selected',
        // slidingSubmenus: true,
        theme: 'light',
        title: 'Menü'
      });

      var drawer = menu.offcanvas({
        position: 'right'
      });

      //  Open the menu.
      document.querySelector('#nav-icon')
        .addEventListener('click', evnt => {
          evnt.preventDefault();
          drawer.open();
        });

      $(".mob-menu-close").click(function () {
        drawer.close();
      });
    }

  });
})(jQuery);